import React, {useEffect} from 'react';
import './style.css';
import Announcements from '../Announcements';
import NewsItem from '../NewsItems';
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {bringNews, clearNews} from "../../store/newsSlice";

function NewsComponent() {
    const dispatch = useDispatch<AppDispatch>();
    const news = useSelector((state: RootState) => state.news);

    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(bringNews(0));
        }, 0);
        return () => {
            dispatch(clearNews());
            clearTimeout(getData);
        }
    }, [dispatch]);
    if (news.loading === true) {
        return <p>Cargando noticias...</p>;
    }
    else if (news.loading === false && news.noticias.length === 0) {
        return <p>No hay noticias disponibles</p>;
    }
    return (
    <div>
        {news.noticias.map((noticia) => (
            <NewsItem key={noticia.id} title={noticia.title} content={noticia.content}
                      author={noticia.author} date={noticia.date}/>
        ))}
    </div>
    );
}

const Home: React.FC = () => {
    return (
        <div className="Home">
            <Announcements/>
            <div className="news-section">
                <h2>Noticias</h2>
                <NewsComponent />
            </div>
        </div>
    );



};

export default Home;