import React from 'react';
import './style.css';

interface NewsItemProps {
  title: string;
  content: string;
  author: string;
  date: string;
}

const NewsItem: React.FC<NewsItemProps> = ({ title, content, author, date }) => {
  return (
    <article className="NewsItem">
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="author-date">
        <span>{author}</span>
        <span>{date}</span>
      </div>
    </article>
  );
};

export default NewsItem;