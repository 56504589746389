import React, { useState, useRef, useCallback } from 'react';
import './index.css';

interface DateInputProps {
    onChange: (date: Date) => void;
}

const DateInput: React.FC<DateInputProps> = ({ onChange }) => {
    const [day, setDay] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const dayRef = useRef<HTMLInputElement>(null);
    const monthRef = useRef<HTMLInputElement>(null);
    const yearRef = useRef<HTMLInputElement>(null);

    const isValidDate = useCallback((day: number, month: number, year: number): boolean => {
        if (year < 1000 || year > 9999 || month === 0 || month > 12) {
            return false;
        }

        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    }, []);

    const handleInputChange = (setFunction: React.Dispatch<React.SetStateAction<string>>, nextRef: React.RefObject<HTMLInputElement>, value: string) => {
        setFunction(value);

        if (value.length === 2 && nextRef.current) {
            nextRef.current.focus();
        }
    };

    const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if((e.target.value.length <= 2 && parseInt(e.target.value, 10) <= 31) || e.target.value.length === 0)
            handleInputChange(setDay, monthRef, e.target.value);
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if((e.target.value.length <= 2 && parseInt(e.target.value, 10) <= 12) || e.target.value.length === 0)
            handleInputChange(setMonth, yearRef, e.target.value);
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if((e.target.value.length <= 4 && parseInt(e.target.value, 10) <= 2100) || e.target.value.length === 0)
            handleInputChange(setYear, yearRef, e.target.value);
    };

    const handleBlur = () => {
        const d = parseInt(day, 10);
        const m = parseInt(month, 10);
        const y = parseInt(year, 10);

        if (isValidDate(d, m, y)) {
            onChange(new Date(y, m - 1, d));
        }
    };

    return (
        <div className="date-input-wrapper">
            <input ref={dayRef} type="text" value={day} onChange={handleDayChange} onBlur={handleBlur} maxLength={2} className="date-input" placeholder='dd' />
            <span className="date-input-slash">/</span>
            <input ref={monthRef} type="text" value={month} onChange={handleMonthChange} onBlur={handleBlur} maxLength={2} className="date-input" placeholder='mm' />
            <span className="date-input-slash">/</span>
            <input ref={yearRef} type="text" value={year} onChange={handleYearChange} onBlur={handleBlur} maxLength={4} className="date-input" placeholder='yyyy' />
        </div>
    );
};

export default DateInput;