import React, {useEffect} from 'react';
import './style.css';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {bringLatestAnuncio, clearAnuncio} from "../../store/newsSlice";

const Announcements: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const news = useSelector((state: RootState) => state.news);

    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(bringLatestAnuncio());
        }, 0);
        return () => {
            dispatch(clearAnuncio());
            clearTimeout(getData);
        }
    }, [dispatch]);
    if (news.anuncio === '') return null;

  return (
      <div className="announcement-section">
        <h2>Anuncio</h2>
        <p>{news.anuncio}</p>
      </div>
  );
};

export default Announcements;