import React, {useEffect} from 'react';
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {bringTema, clearForo} from "../../store/foroSlice";
import {ForoState, PostResume} from "../../store/models/foro";
import {Grid} from "@mui/material";
import './style.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import {formatDate} from "../../utils/date";
import pinned from '../../icons/megaphone.png';
import blocked from '../../icons/padlock.png';
import Paginnator from "../Paginator";
import Breadcrumbs from "./Breadcrumbs";

interface PostsBoxProps {
    data: PostResume
}

const PostBox: React.FC<PostsBoxProps> = ({data}) => {
    return (
        <Grid item xs={12} className="post">
            <Grid container>
                <Grid item xs={4} md={7} className={`post_titulo ${data.lastComentario.has_seen_last_comment?'':'notSeen'}`}>

                    <h4>{data.pinned && <img src={pinned} alt={'pinned'} /> }{data.bloqueado && <img src={blocked} alt={'blocked'} /> }<Link to={`/foro/post/${data.post_id}`}>{data.nombre}</Link></h4>
                    <span>creado por {data.autor}</span>
                </Grid>
                <Grid item xs={2} md={1} className="post_comentarios">
                    <p>{data.total_comentarios}</p>
                </Grid>
                <Grid item xs={2} md={1} className="post_vistas">
                    <p>{data.total_views}</p>
                </Grid>
                <Grid item xs={4} md={3} className="post_lastComentario">
                    <Link to={`/foro/post/${data.post_id}/${Math.ceil(data.total_comentarios/14)}`}><p>{formatDate(data.lastComentario.fecha)}<span> por {data.lastComentario.autor}</span></p></Link>
                </Grid>

            </Grid>
        </Grid>
    );
};

const Tema: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const params = useParams();
    const foro: ForoState = useSelector((state: RootState) => state.foro);

    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(bringTema({page: params.page as unknown as number || 1, tema_id: params.id as unknown as number}));
        }, 0);
        return () => {
            dispatch(clearForo());
            clearTimeout(getData);
        }
    }, [dispatch, params]);

    const callback = (page: number) => {
        navigation(`/foro/tema/${params.id}/${page}`);
    }

    return (
        <div className="Foro">
            <Breadcrumbs />
            <Grid container>
                <Grid item xs={6}>
                    <Paginnator currentPage={foro.tema.page} total={Math.ceil(foro.tema.posts_totales/14)} callback={callback}/>
                </Grid>
                {foro.tema.canPost &&
                <Grid item xs={6} className="foro-action-buttons" justifyContent="flex-end">
                    <Link to={`/foro/tema/${params.id}/create`}>Crear post</Link>
                </Grid>
                }
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className="foro_titulo">
                        <Grid item xs={4} md={7}>
                            <h2>{foro.tema.nombre}</h2>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <p>Comentarios</p>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <p>Vistas</p>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <p>Ultimo comentario</p>
                        </Grid>
                    </Grid>
                </Grid>
                {foro.tema.posts.map((data) => (
                    <PostBox key={data.post_id} data={data}/>
                ))}
            </Grid>

        </div>
    );
};

export default Tema;