import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NewsState } from './models/news';


const initialState: NewsState = {
    totalNoticias: 0,
    noticiasPerPage: 0,
    noticias: [],
    anuncio: '',
    loading: false,
    currentPage: 0
};

export const bringNews = createAsyncThunk(
    'news/bringNews',
    async (page: number, {getState}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/noticias?page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        });
        return response.json();
    });

export const bringLatestAnuncio = createAsyncThunk(
    'news/bringLatestAnuncio',
    async (_, { getState}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/noticias/anuncios`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        });
        return response.json();
    }
)

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        clearNews: (state) => {
            state.totalNoticias = initialState.totalNoticias;
            state.noticiasPerPage = initialState.noticiasPerPage;
            state.noticias = initialState.noticias;
            state.loading = initialState.loading;
            state.currentPage = initialState.currentPage;
        },
        clearAnuncio: (state) => {
            state.anuncio = initialState.anuncio;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bringNews.pending, (state) => {
                state.loading = true;
            })
            .addCase(bringNews.fulfilled, (state, action) => {
                state.loading = false;
                state.totalNoticias = action.payload.totalNoticias;
                state.noticiasPerPage = action.payload.noticiasPerPage;
                state.noticias = action.payload.noticias;
                state.currentPage = action.payload.currentPage;
            })
            .addCase(bringNews.rejected, (state) => {
                state.loading = false;

            })
            .addCase(bringLatestAnuncio.fulfilled, (state, action) => {
                state.anuncio = action.payload.content;
            })
            .addCase(bringLatestAnuncio.rejected, (state) => {
                state.anuncio = '';
            })
    }
});

export const { clearNews, clearAnuncio } = newsSlice.actions;

export default newsSlice.reducer;