import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {Link, Navigate} from 'react-router-dom';
import LoadingIcon from '../../icons/loading.png';
import './style.css';
import "react-date-picker/dist/DatePicker.css";
import {FormState, registerPetition} from "../../store/models/register";
import {sendRegisterPetition} from "../../store/registerSlice";
import DateInput from "../DateInput";

const SignUp: React.FC = () => {

    const [formState, setFormState] = useState<FormState>({
        username: { value: '', error: '', touched: false },
        email: { value: '', error: '', touched: false },
        password: { value: '', error: '', touched: false },
        confirmPassword: { value: '', error: '', touched: false },
        isOver18: { value: false, error: '', touched: false },
        hasReadRules: { value: false, error: '', touched: false },
        dateOfBirth: { value: new Date(), error: '', touched: false },
    });

    const [submitAttempted, setSubmitAttempted] = useState(false);

    const regStatus = useSelector((state: RootState) => state.register);
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();

    // Handle form submission
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitAttempted(true);
        // Dispatch your register action here
        const petition: registerPetition = {
            usuario: formState.username.value as string,
            email: formState.email.value as string,
            password: formState.password.value as string,
            mayorEdad: formState.isOver18.value as boolean,
            acceptNormas: formState.hasReadRules.value as boolean,
            fNacimiento: formState.dateOfBirth.value as Date,
        }
        dispatch(sendRegisterPetition(petition));
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    const updateFormState = (field: keyof FormState, value: string | boolean | Date, error = '', touched = true) => {
        setFormState((prevState) => ({
            ...prevState,
            [field]: {
                value,
                error: touched ? error : prevState[field].error,
                touched: prevState[field].touched || touched,
            },
        }));
    };

    const isFormValid =
        !formState.username.error &&
        !formState.email.error &&
        !formState.password.error &&
        !formState.confirmPassword.error &&
        !formState.isOver18.error &&
        !formState.hasReadRules.error &&
        formState.username.value &&
        formState.email.value &&
        formState.password.value &&
        formState.confirmPassword.value &&
        formState.isOver18.value &&
        formState.hasReadRules.value;

    if (user.token !== '')
        return <Navigate to={'/'} />

    return (
        <div className="SignUp">
            <h1>Registro</h1>
            <form onSubmit={handleSubmit}>
                <div className={`input-group ${formState.username.error && (formState.username.touched || submitAttempted) ? 'input-error' : ''}`}>
                    <label htmlFor="username">
                        Usuario
                        <input
                            type="text"
                            value={formState.username.value as string}
                            onChange={(e) => {
                                const value = e.target.value;
                                const error = value.length < 4 ? 'Usuario debe ser de al menos 4 caracteres.' : '';
                                updateFormState('username', value, error);
                            }}
                        />
                    </label>
                    {(formState.username.error && (formState.username.touched || submitAttempted)) && (
                        <div className="error-message">{formState.username.error}</div>
                    )}
                </div>
                <div className={`input-group ${formState.email.error && (formState.email.touched || submitAttempted) ? 'input-error' : ''}`}>
                    <label>
                        Correo electrónico
                        <input
                            type="email"
                            value={formState.email.value as string}
                            onChange={(e) => {
                                const value = e.target.value;
                                const error = isEmailValid(value) ? '' : 'Correo invalido.';
                                updateFormState('email', value, error);
                            }}
                        />
                    </label>
                    {(formState.email.error && (formState.email.touched || submitAttempted)) && (
                        <div className="error-message">{formState.email.error}</div>
                    )}
                </div>
                <div className={`input-group ${formState.password.error && (formState.password.touched || submitAttempted) ? 'input-error' : ''}`}>
                    <label>
                        Contraseña
                        <input
                            type="password"
                            value={formState.password.value as string}
                            onChange={(e) => {
                                const value = e.target.value;
                                const error = value.length >= 4 ? '' : 'Las contraseñas deben tener al menos 4 caracteres.';
                                updateFormState('password', value, error);
                            }}
                        />
                    </label>
                    {(formState.password.error && (formState.password.touched || submitAttempted)) && (
                        <div className="error-message">{formState.password.error}</div>
                    )}
                </div>
                <div className={`input-group ${formState.confirmPassword.error && (formState.confirmPassword.touched || submitAttempted) ? 'input-error' : ''}`}>
                    <label>
                        Confirmar contraseña
                        <input
                            type="password"
                            value={formState.confirmPassword.value as string}
                            onChange={(e) => {
                                const value = e.target.value;
                                const error = formState.password.value !== value ? 'Las contraseñas no coinciden.' : '';
                                updateFormState('confirmPassword', value, error);
                            }}
                        />
                    </label>
                    {(formState.confirmPassword.error && (formState.confirmPassword.touched || submitAttempted)) && (
                        <div className="error-message">{formState.confirmPassword.error}</div>
                    )}
                </div>
                <div className={`input-group`}>
                    <label>
                        Fecha de nacimiento
                        <DateInput
                            onChange={(date: Date | null) => updateFormState('dateOfBirth', date || new Date(), '', true)}
                        />
                    </label>
                </div>
                <div className={`checkbox-group ${formState.isOver18.error && (formState.isOver18.touched || submitAttempted) ? 'input-error' : ''}`}>
                    <label>
                        <input
                            type="checkbox"
                            checked={formState.isOver18.value as boolean}
                            onChange={(e) => {
                                const value = e.target.checked;
                                const error = value ? '' : 'Debes ser mayor de 18 años.';
                                updateFormState('isOver18', value, error);
                            }}
                        />
                        Declaro correctamente que soy mayor de 18 años.
                    </label>
                    {(formState.isOver18.error && (formState.isOver18.touched || submitAttempted)) && (
                        <div className="error-message">{formState.isOver18.error}</div>
                    )}
                </div>
                <div className={`checkbox-group ${formState.hasReadRules.error && (formState.hasReadRules.touched || submitAttempted) ? 'input-error' : ''}`}>
                    <label>
                        <input
                            type="checkbox"
                            checked={formState.hasReadRules.value as boolean}
                            onChange={(e) => {
                                const value = e.target.checked;
                                const error = value ? '' : 'Debes leer y aceptar las normas.';
                                updateFormState('hasReadRules', value, error);
                            }}
                        />
                        He leído las <Link to="/normas" target="_blank">normas</Link> y las acepto en su totalidad.
                    </label>
                    {(formState.hasReadRules.error && (formState.hasReadRules.touched || submitAttempted)) && (
                        <div className="error-message">{formState.hasReadRules.error}</div>
                    )}
                </div>

                {regStatus.errorMsg && <div className="error-message">{regStatus.errorMsg}</div>}
            <button type="submit" disabled={!isFormValid || regStatus.loading}>
        {regStatus.loading ? (
            <img src={LoadingIcon} alt="Loading" className="loading-icon" />
        ) : (
            'Registrarse'
        )}
        </button>
        </form>
    </div>
);
};

export default SignUp;