import React, {useEffect} from 'react';
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {bringPost, clearForo, editPost} from "../../store/foroSlice";
import {ForoState, Comentarios} from "../../store/models/foro";
import {Grid} from "@mui/material";
import './style.css';

import defaultAvatar from '../../img/kafra.jpg';
import {useNavigate, useParams} from "react-router-dom";
import {formatDate} from "../../utils/date";
import Paginnator from "../Paginator";
import Breadcrumbs from "./Breadcrumbs";
import NewComment from "./NewComment";

interface PostsBoxProps {
    data: Comentarios
}

const CommentBox: React.FC<PostsBoxProps> = ({data}) => {
    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const foro: ForoState = useSelector((state: RootState) => state.foro);
    const editPostPetition = () => {
        dispatch(editPost({
            ...foro.postEdition,
            post_id: params.id as unknown as number,
            tema_id: foro.post.tema_id,
            nombre: foro.post.nombre,
            contenido: data.contenido || '',
            comentario_id: data.comentario_id,
            page: foro.post.page
        }));
        navigation(`/foro/edit/${params.id}`)
    }
    // @ts-ignore
    return (
        <Grid item xs={12} className={`postCard`}>
            <Grid container>
                <Grid item xs={3} md={2} className={`post_tarjeta`}>
                            <h4 className={`post_author`}>{data.autor}</h4>
                            <img src={data.avatar?data.avatar:defaultAvatar} alt='avatar' className='post_avatar'/>
                            <p className={`post_date`}>{formatDate(data.fecha)}</p>
                            <Grid container>
                                <Grid item xs={12} className={`post_likes`}>
                                    <p>🔥 {data.likes}</p>
                                    <p>😤 {data.dislikes}</p>
                                </Grid>
                                <Grid item xs={12} className={`post_buttons`}>
                                    {data.canEdit && <button onClick={editPostPetition}>✏️</button>}
                                </Grid>
                            </Grid>
                </Grid>
                <Grid item xs={9} className={`postContenido`}>
                    <div dangerouslySetInnerHTML={{ __html: data.contenido }}></div>
                </Grid>

            </Grid>
        </Grid>
    );
};

const Post: React.FC = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const foro: ForoState = useSelector((state: RootState) => state.foro);

    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(bringPost({page: params.page as unknown as number || 1, post_id: params.id as unknown as number}));
        }, 0);
        return () => {
            dispatch(clearForo());
            clearTimeout(getData);
        }
    }, [dispatch, params.page, params.id]);

    const callback = (page: number) => {
        navigation(`/foro/post/${params.id}/${page}`)
    }

    return (
        <div className="Foro">
            <Breadcrumbs/>
            <Grid container>
                <Grid item xs={12}>
                    <Paginnator total={Math.ceil(foro.post.comentarios_totales/14)} currentPage={foro.post.page} callback={callback} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className="foro_titulo">
                        <Grid item xs={12} md={12}>
                            <h2>{foro.post.blocked?'[Bloqueado]':''}{foro.post.pinned?'[Resaltado]':''} {foro.post.nombre}</h2>
                        </Grid>
                    </Grid>
                </Grid>
                {foro.post.comentarios.map((data) => (
                    <CommentBox key={data.comentario_id} data={data}/>
                ))}
            </Grid>
            <NewComment post_id={foro.post.post_id} />
        </div>
    );
};

export default Post;