import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { User } from './models/user';
import {sendRegisterPetition} from "./registerSlice";


const initialState: User = {
  token: '',
  username: '',
  loadingLogin: 0,
  errorMsg: ''
};

export const checkLoginDetails = createAsyncThunk(
    'user/checkLoginDetails',
    async (loginDetails: {username: string, password: string, rememberSession: boolean}, thunkAPI) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginDetails),
        });
        if (!response.ok){
            return thunkAPI.rejectWithValue(await response.json());
        }
        return response.json();
    });


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.username = '';
      state.token = '';
      state.loadingLogin = 0;
    },
      applyToken: (state, action) => {
          state.token = action.payload.token;
          state.username = action.payload.username;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkLoginDetails.pending, (state) => {
        state.loadingLogin = 1;
      })
      .addCase(checkLoginDetails.fulfilled, (state, action) => {
        state.loadingLogin = 3;
        state.token = action.payload.token;
        state.username = action.payload.username;
        state.errorMsg = '';
      })
      .addCase(checkLoginDetails.rejected, (state, action) => {
        state.loadingLogin = 2;
        state.errorMsg = action.error.message;
      })
        .addCase(sendRegisterPetition.fulfilled, (state, action) => {
            state.loadingLogin = 3;
            state.token = action.payload.token;
            state.username = action.payload.username;

        });
  }
});

export const { logout, applyToken } = userSlice.actions;
export default userSlice.reducer;