import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {ForoState} from "../../store/models/foro";
import {bringUserAuthors, clearEditPost, sendComentarioEdition} from "../../store/foroSlice";
import {Editor} from "@tinymce/tinymce-react";

const EditComment: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const foro: ForoState = useSelector((state: RootState) => state.foro);
    const [sendComment, setSendComment] = React.useState(false);
    const [content, setContent] = React.useState(foro.postEdition.contenido);
    const [author, setAuthor] = React.useState(foro.postEdition.autor);

    useEffect(() => {
        if(!sendComment && foro.userAvailableAuthors.length > 0 && !author)
            setAuthor(foro.userAvailableAuthors[0].author_id);
    }, [foro.userAvailableAuthors, sendComment, author]);

    useEffect(() => {
        dispatch(bringUserAuthors({tema_id: foro.postEdition.tema_id}));
    }, [dispatch, foro.postEdition.tema_id]);

    const handleEditarPost = () => {
        setSendComment(true);
        dispatch(sendComentarioEdition({comentario_id: foro.postEdition.comentario_id, contenido: content, author_id: author}));
    }

    useEffect(() => {
        if(foro.postEdition.status === 3){
            dispatch(clearEditPost());
            navigation(`/foro/post/${foro.postEdition.post_id}/${foro.postEdition.page}`);
            setContent('');
        }
    }, [dispatch, foro.postEdition, navigation]);

    return (
        <div>
            <h1>Editar comentario</h1>
            <Editor
                apiKey='rry642psd5iu54x5udcsftnnkr573xudang4agc8ayuj7lez'
                onEditorChange={(cont) => {
                    setContent(cont);
                }}
                value={content}
                init={{
                    height: 350,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'image'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | image | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            {foro.userAvailableAuthors.length > 1 &&
                <div className={'form-group'}>
                    <label htmlFor="autor">Autor</label>
                    <select className="form-control" id="autor" value={author} onChange={(event) => setAuthor(parseInt(event.target.value))}>
                        {foro.userAvailableAuthors.map((autor) => {
                                return <option key={autor.author_id} value={autor.author_id}>{autor.nombre}</option>
                            }
                        )}
                    </select>
                </div>
            }
            {foro.postEdition.status === 2 &&
                <div className={'alert alert-danger'} role="alert">
                    Error al intentar responder en el post.
                </div>
            }
            <button className={'btn btn-primary'} onClick={handleEditarPost} disabled={(content.length < 15)}>Editar</button>
        </div>
    );
}

export default EditComment;