import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {Navigate, useParams} from "react-router-dom";
import {ForoState, PostPetition} from "../../store/models/foro";
import {useEffect, useState} from "react";
import {bringTema, bringUserAuthors, clearForo, sendPostCreation} from "../../store/foroSlice";
import styled from 'styled-components';
import Breadcrumbs from "./Breadcrumbs";
import { Editor } from '@tinymce/tinymce-react';
import loader from '../../icons/loading.png';

const ContainerDiv = styled.div`
    max-width: 1200px;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    padding: 0 15px;
`;

const SubmitButton = styled.button`
  background-color: rgb(154, 72, 208);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: rgb(221, 180, 248);
    border-color: #6c757d;
    cursor: not-allowed;
  }
`;

const Loader = styled.img`
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: block;
`;

const ErrorDiv = styled.div`
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
`;

const CreatePost = () => {
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const foro: ForoState = useSelector((state: RootState) => state.foro);

    const [formState, setFormState] = useState({
        titulo: foro.postEdition.nombre || '',
        contenido: foro.postEdition.contenido || '',
        autor: foro.postEdition.autor || 0
    });
    const [submitAttempted, setSubmitAttempted] = useState(false);

    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(bringTema({page: 1, tema_id: params.id as unknown as number}));
            dispatch(bringUserAuthors({tema_id: params.id as unknown as number}));
        }, 0);
        return () => {
            dispatch(clearForo());
            clearTimeout(getData);
        }
    }, [dispatch, params]);

    useEffect(() => {
        if (formState.autor === 0 && foro.userAvailableAuthors.length > 0) {
            setFormState({...formState, autor: foro.userAvailableAuthors[0].author_id});
        }
    }, [formState, foro.userAvailableAuthors]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitAttempted(true);
        const petition: PostPetition = {
            tema_id: foro.tema.tema_id,
            nombre: formState.titulo,
            contenido: formState.contenido,
            author_id: formState.autor
        }
        dispatch(sendPostCreation(petition));
    };

    const isFormInvalid = formState.titulo.length < 3 || formState.contenido.length < 5 || (submitAttempted && foro.postEdition.loading);

    if (submitAttempted && foro.postEdition.loading) {
        return (
            <div className={'Foro'}>
                <Breadcrumbs />
                <ContainerDiv>
                    <h2>Creando post en {foro.tema.nombre}</h2>
                    <Loader src={loader} alt="loader" />
                </ContainerDiv>
            </div>
        )
    }

    if (submitAttempted && !foro.postEdition.loading && foro.postEdition.status === 3)
        return (
            <Navigate to={`/foro/post/${foro.postEdition.post_id}`} />
        )

    return (
        <div className={'Foro'}>
            <Breadcrumbs />
            <ContainerDiv>
                <h2>Crear post en {foro.tema.nombre}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="titulo">Título</label>
                        <input type="text" value={formState.titulo} className="form-control" onChange={(evt) => setFormState({...formState, titulo: evt.target.value})} id="titulo" placeholder="Título del post" />
                    </div>
                    <div className="form-group">
                        <Editor
                            apiKey='rry642psd5iu54x5udcsftnnkr573xudang4agc8ayuj7lez'
                            onEditorChange={(content) => {
                                setFormState({...formState, contenido: content});
                            }}
                            value={formState.contenido}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'image'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | image | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
                    <div className={'form-group'}>
                        <label htmlFor="autor">Autor</label>
                        <select className="form-control" id="autor" value={formState.autor} onChange={(event) => setFormState({...formState, autor: parseInt(event.target.value)})}>
                            {foro.userAvailableAuthors.map((autor) => {
                                    return <option key={autor.author_id} value={autor.author_id}>{autor.nombre}</option>
                            }
                            )}
                        </select>
                    </div>
                    {submitAttempted && foro.postEdition.status === 2 && <ErrorDiv className="alert alert-danger" role="alert">Error al crear el post</ErrorDiv>}
                    <SubmitButton type="submit" className="btn btn-primary" disabled={isFormInvalid}>Crear post</SubmitButton>
                </form>
            </ContainerDiv>
        </div>
    );
}

export default CreatePost