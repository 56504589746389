export function formatDate(dateString: string): string {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Extract the components of the date and time
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    // Return the formatted string
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}
