import styles from './style.module.css';
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
const ErrorPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        let redirect = setTimeout(() => {

            navigate('/');
        }, 5000);
        return () => {
            clearTimeout(redirect);
        }
    });

    return (
        <div className={styles.errorMessage}>
            <div className={styles.textoError}>
                <h1>404</h1>
                <h2>Pagina no encontrada u ocurrio un problema.</h2>
                <h5>Te redirigiremos pronto ahora... o <Link to='/'>Volver al inicio ya</Link></h5>

            </div>

        </div>
    );
}

export default ErrorPage;