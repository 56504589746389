import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './style.css';
import { Link } from 'react-router-dom';
import { logout } from '../../store/userSlice';

const NavigationMenu: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  const user = useSelector((state: RootState) => state.user.username);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      if (window.innerWidth > 800) {
        setMenuVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (user === ''){
    return (
<div className={`NavigationMenu ${menuVisible ? 'visible' : 'notVisible'}`}>
      {isMobile && (
        <span className="hamburger" onClick={toggleMenu}>
          &#9776;
        </span>
      )}
      {(menuVisible || !isMobile) && (
        <nav className="nav">
          <ul>
            <li>
              <Link onClick={toggleMenu} to="/">
                Inicio
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/">
                Normas
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/registro">
                Registro
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/login">
                Login
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
    );
  }
  return (
    <div className={`NavigationMenu ${menuVisible ? 'visible' : 'notVisible'}`}>
      {isMobile && (
        <span className="hamburger" onClick={toggleMenu}>
          &#9776;
        </span>
      )}
      {(menuVisible || !isMobile) && (
        <nav className="nav">
          <ul>
            <li>
              <Link onClick={toggleMenu} to="/">
                Inicio
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/dashboard">
                Cronologia
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/">
                Personaje
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/">
                Normas
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/foro">
                Foro
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/">
                Panel de control
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/descargar">
                Descargas
              </Link>
            </li>
            <li>
              <span onClick={handleLogout} style={{ cursor: 'pointer' }}>
                Logout
              </span>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default NavigationMenu;