import {Editor} from "@tinymce/tinymce-react";
import loading from '../../icons/loading.png';
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {ForoState} from "../../store/models/foro";
import React, {useEffect} from "react";
import {bringPost, bringUserAuthors, clearEditPost, editPost, sendComentarioCreation} from "../../store/foroSlice";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NewComment: React.FC<{post_id: number}> = ({post_id}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const foro: ForoState = useSelector((state: RootState) => state.foro);
    const [sendComment, setSendComment] = React.useState(false);
    const [content, setContent] = React.useState(foro.postEdition.contenido);
    const [author, setAuthor] = React.useState(foro.postEdition.autor);


    useEffect(() => {
        if(!sendComment && foro.userAvailableAuthors.length > 0 && !author)
            setAuthor(foro.userAvailableAuthors[0].author_id);
    }, [foro.userAvailableAuthors, sendComment, author]);

    useEffect(() => {
        if(foro.post.canComment)
            dispatch(bringUserAuthors({tema_id: foro.post.tema_id}));
    }, [dispatch, foro.post.canComment, foro.post.tema_id]);

    const handleResponderPost = () => {
        setSendComment(true);
        dispatch(editPost({...foro.postEdition, contenido: content, autor: author, post_id}));
        dispatch(sendComentarioCreation({post_id, contenido: content, author_id: author}));
    }

    useEffect(() => {
        if(foro.postEdition.status === 3){
            dispatch(clearEditPost());
            if (foro.post.page !== Math.ceil(foro.post.comentarios_totales/14))
                navigation(`/foro/post/${post_id}/${Math.ceil(foro.post.comentarios_totales/14)}`);
            dispatch(bringPost({page: Math.ceil(foro.post.comentarios_totales/14), post_id}))
            setContent('');
        }
    }, [foro.postEdition.status, dispatch, foro.post.page, foro.post.comentarios_totales, navigation, post_id]);

    if(foro.post.canComment && foro.userAvailableAuthors.length >= 0 && (!sendComment || !foro.postEdition.loading)) {
        return (
            <Grid container className={`newCommentBox`}>
                <Grid item xs={12}>
                    <Editor
                        apiKey='rry642psd5iu54x5udcsftnnkr573xudang4agc8ayuj7lez'
                        onEditorChange={(cont) => {
                            setContent(cont);
                        }}
                        value={content}
                        init={{
                            height: 350,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'image'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | image | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    {foro.userAvailableAuthors.length > 1 &&
                        <div className={'form-group'}>
                            <label htmlFor="autor">Autor</label>
                            <select className="form-control" id="autor" value={author} onChange={(event) => setAuthor(parseInt(event.target.value))}>
                                {foro.userAvailableAuthors.map((autor) => {
                                        return <option key={autor.author_id} value={autor.author_id}>{autor.nombre}</option>
                                    }
                                )}
                            </select>
                        </div>
                    }
                    {foro.postEdition.status === 2 &&
                        <div className={'alert alert-danger'} role="alert">
                            Error al intentar responder en el post.
                        </div>
                    }
                    <button className={'btn btn-primary'} onClick={handleResponderPost} disabled={(!foro.post.canComment || content.length < 15)}>Responder</button>
                </Grid>
            </Grid>
        );
    }
    return (
        <div>
            <img src={loading} alt="loading" className={'loading'} />
        </div>
    )
}

export default NewComment;
