import React from 'react';
import Pagination from '@mui/material/Pagination';

interface PaginatorProps {
    total: number;
    currentPage: number;
    callback: Function;
}

const Paginnator: React.FC<PaginatorProps> = ({total, currentPage, callback}) => {

    const handlePageClick = (_: any, value: number) => {
        if(value === currentPage)
            return;
        callback(value);
    }

    return (
        <div className="paginator">
            <Pagination count={total} page={currentPage} onChange={handlePageClick} />
        </div>
    );

}

export default Paginnator;