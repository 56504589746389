import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {ForoState, PostEdition, PostPetition} from './models/foro';
import {logout} from "./userSlice";

const initialState: ForoState = {
    categorias: [],
    tema: {
        tema_id: 0,
        nombre: '',
        publico: true,
        posts_totales: 0,
        canPost: false,
        page: 0,
        posts: []
    },
    post: {
        post_id: 0,
        tema: '',
        tema_id: 0,
        nombre: '',
        pinned: false,
        blocked: false,
        creador: '',
        comentarios_totales: 0,
        canComment: false,
        page: 0,
        comentarios: []
    },
    postEdition: {
        tema_id: 0,
        post_id: 0,
        autor: 0,
        nombre: '',
        comentario_id: 0,
        contenido: '',
        loading: false,
        status: 0,
        page: 1
    },
    userAvailableAuthors: []
};

export const bringAllForum = createAsyncThunk(
    'foro/bringAllForo',
    async (_, {getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        });
        if(response.status === 401)
            dispatch(logout());
        return response.json();
});

export const sendPostCreation = createAsyncThunk(
    'foro/sendPostCreation',
    async (petition: PostPetition,{getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro/createPost`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify(petition)
        });
        if(response.status === 401)
            dispatch(logout());

        return response.json();
        }
)

export const sendComentarioCreation = createAsyncThunk(
    'foro/sendComentarioCreation',
    async (petition: {post_id: number, contenido: string, author_id: number},{getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro/createComment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify(petition)
        });
        if(response.status === 401)
            dispatch(logout());
        return response.json();
    }
);

export const sendComentarioEdition = createAsyncThunk(
    'foro/sendComentarioEdition',
    async (petition: {comentario_id: number, contenido: string, author_id: number},{getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro/editComment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify(petition)
        });
        if(response.status === 401)
            dispatch(logout());
        return response.json();
    }
);

export const bringTema = createAsyncThunk(
    'foro/bringTema',
    async ({tema_id, page}: {tema_id: number, page: number},{getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro/tema?tema_id=${tema_id}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        });
        if(response.status === 401)
            dispatch(logout());
        return response.json();
});

export const bringUserAuthors = createAsyncThunk(
    'foro/bringUseruthors',
    async ({tema_id}: {tema_id: number},{getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro/authors?tema=${tema_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        });
        if(response.status === 401)
            dispatch(logout());
        //if response.status is 400, reject the promise
        if(response.status === 400)
            return Promise.reject(response.json());
        return response.json();
    });

export const bringPost = createAsyncThunk(
    'foro/bringPost',
    async ({post_id, page}: {post_id: number, page: number},{getState, dispatch}) => {
        // @ts-ignore
        const token = getState().user.token;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foro/post?post_id=${post_id}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        });
        if(response.status === 401)
            dispatch(logout());

        return response.json();
});

const foroSlice = createSlice({
    name: 'foro',
    initialState,
    reducers: {
        clearForo: (state) => {
            state.categorias = initialState.categorias;
            state.tema = initialState.tema;
            state.post = initialState.post;
        },
        editPost: (state, action: PayloadAction<PostEdition>) => {
            state.postEdition = action.payload;
        },
        clearEditPost: (state) => {
            state.postEdition = initialState.postEdition;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bringAllForum.pending, (state) => {
                state.categorias = [];
            })
            .addCase(bringAllForum.rejected, (state) => {
                state.categorias = [];
            })
            .addCase(bringAllForum.fulfilled, (state, action) => {
                state.categorias = action.payload.categorias;
            })
            .addCase(bringTema.pending, (state) => {
                state.tema = initialState.tema;
            })
            .addCase(bringTema.rejected, (state) => {
                state.tema = initialState.tema;
            })
            .addCase(bringTema.fulfilled, (state, action) => {
                state.tema = action.payload.tema;
            })
            .addCase(bringPost.pending, (state) => {
                state.post = initialState.post;
            })
            .addCase(bringPost.rejected, (state) => {
                state.post = initialState.post;
            })
            .addCase(bringPost.fulfilled, (state, action) => {
                state.post = action.payload.post;
            })
            .addCase(sendPostCreation.pending, (state) => {
                state.postEdition.loading = true;
                state.postEdition.status = 1;
            })
            .addCase(sendPostCreation.rejected, (state) => {
                state.postEdition.loading = false;
                state.postEdition.status = 2;
            })
            .addCase(sendPostCreation.fulfilled, (state, action) => {
                state.postEdition.loading = false;
                state.postEdition.status = 3;
                state.postEdition.post_id = action.payload.post_id;
            })
            .addCase(bringUserAuthors.pending, (state) => {
                state.userAvailableAuthors = [];
            })
            .addCase(bringUserAuthors.rejected, (state) => {
                state.userAvailableAuthors = [];
            })
            .addCase(bringUserAuthors.fulfilled, (state, action) => {
                state.userAvailableAuthors = action.payload.autores;
            })
            .addCase(sendComentarioCreation.fulfilled, (state) => {
                state.post.comentarios_totales = state.post.comentarios_totales + 1;
                state.postEdition.loading = false;
                state.postEdition.status = 3;
            })
            .addCase(sendComentarioCreation.pending, (state) => {
                state.postEdition.loading = true;
                state.postEdition.status = 1;
            })
            .addCase(sendComentarioCreation.rejected, (state) => {
                state.postEdition.loading = false;
                state.postEdition.status = 2;
            })
            .addCase(sendComentarioEdition.fulfilled, (state) => {
                state.postEdition.loading = false;
                state.postEdition.status = 3;
                state.postEdition.contenido = '';
            })
            .addCase(sendComentarioEdition.pending, (state) => {
                state.postEdition.loading = true;
                state.postEdition.status = 1;
            })
            .addCase(sendComentarioEdition.rejected, (state) => {
                state.postEdition.loading = false;
                state.postEdition.status = 2;
            })
        ;
    }
});

export const { clearForo, editPost, clearEditPost } = foroSlice.actions;

export default foroSlice.reducer;