import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Bona Nova', serif;
        margin: 0;
        padding: 0;
        background-color: #f3f3f9;
        color: #4b4b4b;
    }
    a {
        text-decoration: none;
        color: #ffffff;
    }
    a:hover {
        color: #9a48d0;
    }
    button {
        background-color: #553c9a;
        border: none;
        padding: 10px 20px;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    button:hover {
        background-color: #9a48d0;
    }
    .header {
        background-color: #553c9a;
        padding: 20px 10em;
        text-align: left;
        color: #fff;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .header h1 {
        margin: 0;
      }
      
      .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
      }

    .content {
        padding-left: 15em;
        padding-right: 15em;
    }

    @media (max-width: 800px) {
        .header {
          padding: 20px 2em;
        }
      }
      
      @media (max-width: 500px) {
        .header {
          padding: 20px 1em;
        }
      }
`;