import { createBrowserRouter } from "react-router-dom";
import NormalLayout from "./layout/NormalLayout";
import Home from "./components/Home";
import LoginForm from "./components/LoginForm";
import { RequireAuth } from "./components/RequireAuth";
import SignUp from "./components/SignUp";
import Foro from "./components/Foro";
import Tema from "./components/Foro/tema";
import Post from "./components/Foro/Post";
import ErrorPage from "./components/ErrorPage";
import CreatePost from "./components/Foro/CreatePost";
import EditComment from "./components/Foro/EditComment";

const router = createBrowserRouter([
    {
        path: '/',
        element: <NormalLayout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/dashboard', element: <RequireAuth><div>Dashboard</div></RequireAuth> },
            { path: '/characters', element: <div>Characters</div> },
            { path: '/game-management', element: <div>Game Management</div> },
            { path: '/foro', element: <RequireAuth><Foro /></RequireAuth> },
            { path: '/foro/tema/:id', element: <RequireAuth><Tema /></RequireAuth> },
            { path: '/foro/tema/:id/create', element: <RequireAuth><CreatePost /></RequireAuth> },
            { path: '/foro/tema/:id/:page', element: <RequireAuth><Tema /></RequireAuth> },
            { path: '/foro/post/:id', element: <RequireAuth><Post /></RequireAuth>   },
            { path: '/foro/post/:id/:page', element: <RequireAuth><Post /></RequireAuth> },
            { path: '/foro/edit/:id', element: <RequireAuth><EditComment /></RequireAuth> },
            { path: '/login', element: <LoginForm /> },
            { path: '/registro', element: <SignUp /> },
        ],
        errorElement: <ErrorPage />
    }
]);

export default router; // Export the router to be used in src\App.tsx