import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from "../../store/store";

export function RequireAuth({ children }: { children: JSX.Element }) {
    const user = useSelector((state: RootState) => state.user.token);
    let location = useLocation();
  
    if (!user) {
      return <Navigate to="/" state={{ from: location }} replace />;
    } else {
      return children;
    }
}