import React from 'react';
import {Outlet} from 'react-router-dom';
import NavigationMenu from '../components/NavigationMenu';

const NormalLayout: React.FC = () => {
  return (
    <div className="Layout">
      <header className="header">
        <h1>Eternals</h1>
        <NavigationMenu />
      </header>

      <main className="container">
        <Outlet />
      </main>
    </div>
  );
}

export default NormalLayout;