import React, {useEffect} from 'react';
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {bringAllForum, clearForo} from "../../store/foroSlice";
import {Categoria} from "../../store/models/foro";
import {Grid} from "@mui/material";
import './style.css';
import {Link} from "react-router-dom";

interface CategoriaBoxProps {
    data: Categoria
}

const CategoriaBox: React.FC<CategoriaBoxProps> = ({data}) => {
    return (
        <div className="categoria">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className="foro_titulo">
                        <Grid item xs={7} md={8}>
                            <h2>{data.nombre}</h2>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <p>Posts</p>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <p>Ultimo post</p>
                        </Grid>
                    </Grid>
                </Grid>
                {data.temas.map((tema) => (
                    <Grid container key={tema.tema_id} className={`topic`}>
                        <Grid item xs={7} md={8} className={`${tema.comentarios_sin_ver?'notSeen':''}`}>
                            <h4><Link to={`/foro/tema/${tema.tema_id}`}>{tema.nombre}</Link></h4>
                            <span>{tema.descripcion}</span>
                        </Grid>
                        <Grid item xs={1} md={1} className='cantidadPosts'>
                            <p>{tema.numero_posts}</p>
                        </Grid>
                        <Grid item xs={4} md={3} className={`lastPost ${tema.lastPost.visto?'':'notSeen'}`}>
                            <p><Link to={`/foro/post/${tema.lastPost.post_id}`}>{tema.lastPost.nombre}</Link><span> por {tema.lastPost.autor}</span></p>
                            <span>{tema.lastPost.fecha}</span>

                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

const Foro: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const foro = useSelector((state: RootState) => state.foro);

    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(bringAllForum());
        }, 0);
        return () => {
            dispatch(clearForo());
            clearTimeout(getData);
        }
    }, [dispatch]);

    if (foro.categorias.length === 0) return (<div className="Foro"/>);

    return (
        <div className="Foro">
            {foro.categorias.map((data) => (
                <CategoriaBox key={data.nombre} data={data}/>
            ))}
        </div>
    );
};

export default Foro;