import React, {useEffect, useState} from 'react';
import './style.css';
import LoadingIcon from '../../icons/loading.png';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {checkLoginDetails} from "../../store/userSlice";
import {AppDispatch, RootState} from "../../store/store";

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberSession, setRememberSession] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(checkLoginDetails( {username, password, rememberSession}));
  };

  useEffect(() => {
    if (user.loadingLogin === 2) {
      setPassword('');
    }
    if (user.loadingLogin === 3) {
      setUsername('');
      setPassword('');
      navigate('/');
    }
  }, [user.loadingLogin, navigate]);

  const inputClass = user.loadingLogin === 2 ? 'input-error' : '';

  return (
    <div className="LoginForm">
      <h1>Iniciar Sesión</h1>
      <form onSubmit={handleSubmit}>
        <div className={`input-group ${inputClass}`}>
          <label htmlFor="username">Usuario:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className={`input-group ${inputClass}`}>
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {user.loadingLogin === 2 && <p className="error-message">Error: Usuario o contraseña invalida</p>}
        <div className="checkbox-group">
          <input
            type="checkbox"
            id="remember"
            checked={rememberSession}
            onChange={() => setRememberSession(!rememberSession)}
          />
          <label htmlFor="remember">Recordar Sesion</label>
        </div>
        <button type="submit" disabled={user.loadingLogin === 1}>{user.loadingLogin !== 1 ? 'Login' : <img src={LoadingIcon} alt="Loading" className='loading-icon' />}</button>
        <div className="links">
          <Link to="/forgot-password">¿Contraseña olvidada?</Link>
          <Link to="/register">Registro</Link>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;