import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { registerPetition, registerState} from "./models/register";

const initialState: registerState = {
    token: '',
    username: '',
    loading: false,
    errorMsg: '',
    regCorrecto: null
};

export const sendRegisterPetition = createAsyncThunk(
    'register/sendRegisterPetition',
    async (registerDetails: registerPetition) => {

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/registro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registerDetails),
        });
        if (!response.ok) {
            if (response.status === 422) {
                const responseBody = await response.json();
                throw new Error(responseBody.errorMsg || "Error 401");
            }
            else if(response.status === 400) {
                const responseBody = await response.json();
                const firstErrorMessage = responseBody.errors && responseBody.errors[0] ? responseBody.errors[0].text : "Error desconocido";
                throw new Error(firstErrorMessage);
            } else {
                throw new Error("Error desconocido");
            }
        }
        return response.json();
    });


const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendRegisterPetition.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendRegisterPetition.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.token;
                state.username = action.payload.username;
                state.regCorrecto = true;

            })
            .addCase(sendRegisterPetition.rejected, (state, action) => {
                state.loading = false;
                // @ts-ignore
                state.errorMsg = action.error.message || 'Error desconocido';
            });
    }
});

export default registerSlice.reducer;