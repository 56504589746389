import { Link } from "react-router-dom";
import {ForoState} from "../../store/models/foro";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import styled from 'styled-components';

const BreadcrumbsDiv = styled.div`
  padding: 15px 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 1;
`;

const BreadInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BreadList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
`;

const Icon = styled.i`
  margin: 0 5px;
`;

const Breadcrumbs = () => {
    const foro: ForoState = useSelector((state: RootState) => state.foro);

    return (
        <BreadcrumbsDiv>
            <Container>
                <Row>
                    <Col>
                        <BreadInner>
                            <BreadList>
                                <ListItem>
                                    <Link to="/foro">
                                        Foro
                                    </Link>
                                    <Icon className="ti-arrow-right" > {">"} </Icon>
                                </ListItem>
                                {foro.tema.nombre && (
                                    <ListItem>
                                        <Link to={`/foro/tema/${foro.tema.tema_id}/1`}>
                                            {foro.tema.nombre}
                                        </Link>
                                        <Icon className="ti-arrow-right" > {">"} </Icon>
                                    </ListItem>
                                )}
                                {foro.post.tema && (
                                    <ListItem>
                                        <Link to={`/foro/tema/${foro.post.tema_id}/1`}>
                                            {foro.post.tema}
                                        </Link>
                                        <Icon className="ti-arrow-right" > {">"} </Icon>
                                    </ListItem>
                                )}
                                {foro.post.page > 1 && (
                                    <ListItem>
                                        <Link to={`/foro/post/${foro.post.post_id}/1`}>
                                            {foro.post.nombre}
                                        </Link>
                                        <Icon className="ti-arrow-right" > {">"} </Icon>
                                    </ListItem>
                                )}
                            </BreadList>
                        </BreadInner>
                    </Col>
                </Row>
            </Container>
        </BreadcrumbsDiv>
    )
}

export default Breadcrumbs;