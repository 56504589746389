import React from 'react';
import {
  RouterProvider,
} from "react-router-dom";
import { GlobalStyle } from './globalStyle';
import './App.css';
import router from './router';

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <div className="App">
      <GlobalStyle />
      <RouterProvider router={router} />
      </div>
    </React.StrictMode>
  );
}

export default App;