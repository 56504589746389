import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import userReducer from './userSlice';
import newsReducer from './newsSlice';
import registerSlice from './registerSlice';
import foroReducer from './foroSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'], // only persist the 'user' reducer
};

const rootReducer = combineReducers({
    user: userReducer,
    news: newsReducer,
    foro: foroReducer,
    register: registerSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['persist/PERSIST'],
        },
      }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;